<template>
    <div>
        <b-sidebar
            id="sidebar-email"
            ref="sidebarEmail"
            v-model="isShowSidebar"
            aria-labelledby="sidebar-email-header"
            right
            backdrop
            shadow
            no-header
        >
        <div class="px-3">
          <b-row class="header-filter">
            <b-col class="text-filter">Email</b-col>
            <b-col class="text-right">
              <button class="btn-clear" @click.prevent="hide">
                x
              </button>
            </b-col>
          </b-row>
            <div class="mt-3">
                <div v-if="isLoading">
                    <img
                        src="@/assets/icons/loading.svg"
                        class="loading"
                        alt="loading"
                    />
                </div>
                  <div v-else>
                      <div v-if="typeEmail === 'segment' && formMain.segmentationType === 2">
                          <TextEditorEmailCampaign
                              v-if="!isLoading"
                              textFloat="Message"
                              v-model="emailValue"
                              :rows="8"
                              :name="'email_value' + 1"
                              placeholder="Type something..."
                              @onDataChange="(val) => emailValue = val"
                              isRequired
                              :isValidate="errorEmail"
                          />
                          <div v-if="errorEmail" class="mt-1 text-error"> Please input. </div>
                      </div>
                      <div v-else>
                          <TextEditorEmail
                              v-if="!isLoading"
                              textFloat="Message"
                              v-model="emailValue"
                              :rows="8"
                              :name="'email_value' + 1"
                              placeholder="Type something..."
                              @onDataChange="(val) => emailValue = val"
                              isRequired
                              :isValidate="errorEmail"
                          />
                          <div v-if="errorEmail" class="mt-1 text-error"> Please input. </div>
                      </div>
                  </div>
              </div>
            </div>
            <template #footer>
                <div class="d-flex justify-content-between shadow align-items-center">
                    <div class="w-100">
                        <b-button
                            block
                            variant="dark"
                            class="text-light rounded-pill"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                    <div class="w-100">
                        <b-button
                            block
                            variant="light"
                            class="text-body rounded-pill btn-main"
                            @click.prevent="submitEmail"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import TextEditorEmailCampaign from "@/components/automation/detail/TextEditorEmailCampaign";
import TextEditorEmail from "@/components/automation/detail/TextEditorEmail";
export default {
    components:{
        TextEditorEmail,
        TextEditorEmailCampaign
    },
    props:{
        form:{
            required: true,
            type:Array
        },
        typeEmail:{
            required:true,
            type:String
        },
        formMain:{
            required: true,
            type:Object
        },
    },
    watch:{
        isShowSidebar(val){
            if(val){
                setTimeout(async() => {
                    this.isLoading = false;
                },1000);
            }else{
                this.isLoading = true;
            }
        },
        emailValue(val){
            if(val){
                this.errorEmail = false;
            }else{
                this.errorEmail = true;
            }
        }
    },
    data(){
        return {
            isLoading : true,
            isShowSidebar : false,
            variableItem:[
                { id: 1 , name : "firstname"},
                { id : 2 , name : "lastname" },
                { id : 3 , name : "point" }
            ],
            variableItemSegment:[
                { id: 1 , name : "firstname"},
                { id : 2 , name : "lastname" },
                { id : 3 , name : "point" } ,
                { id : 4 , name : "url_web"},
                { id : 5 , name : "url_line"}
            ],
            emailValue : "",
            errorEmail : false
        }
    },
    methods:{
        show() {
            this.isShowSidebar = true;
            for(const item of this.form){
                if(item.communication_type_id === 1){
                    this.emailValue = item.detail_list[0].communication_value[0].value;
                }
            }
        },
        hide() {
            this.isShowSidebar = false;
        },
        submitEmail(){
            if(this.emailValue){
                for(const item of this.form){
                    if(item.communication_type_id === 1){
                        item.detail_list[0].communication_value[0].value = this.emailValue;
                    }
                }
                this.errorEmail = false
                this.$emit('settingEmail',1);
                this.hide();
            }else{
                this.errorEmail = true
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-header{
    font-size: 16px;
    display: block;
    padding: 0 1rem;
}
.bg-color{
    background-color:var(--primary-color);
}
.title-msg{
    color: #000;
    font-size: 17px;
}
.btn-outline{
    background-color: var(--secondary-color);
    border-radius: 50px;
    color: var(--primary-color);
}
::v-deep .btn-secondary{
    background-color: #fff;
    border-color: #fff;
}
::v-deep .rounded-pill{
    border-radius: 0px!important;
}
::v-deep .btn-main, .btn-mains button{
    width: 100%!important;
    background-color: var(--primary-color)!important;
    border: 1px solid var(--primary-color);
}
.text-error{
    color: red;
}
.border-error{
    border-color: red;
}
</style>
