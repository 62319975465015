<template>
    <b-sidebar
        id="sidebar-filter"
        ref="sidebarFilter"
        v-model="isShowSidebar"
        aria-labelledby="sidebar-filter-header"
        right
        backdrop
        shadow
    >
        <template #header="{ hide }">
            <b-row class="d-flex bg-color text-light align-items-center px-3 py-3">
                <b-col><div class="mr-auto">Filter</div></b-col>
                <b-col class="text-right"><font-awesome-icon @click="hide" icon="times-circle"/></b-col>
            </b-row>
        </template>
        <template #footer>
            <div class="d-flex justify-content-between shadow align-items-center">
                <div class="w-100">
                    <b-button
                        block
                        variant="dark"
                        class="text-light rounded-pill"
                        @click="hide"
                    >
                        Cancel
                    </b-button>
                </div>
                <div class="w-100">
                    <b-button
                        @click.prevent="submitAction"
                        block
                        variant="light"
                        class="text-body rounded-pill btn-main"
                    >
                        Save
                    </b-button>
                </div>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
export default {
    data(){
        return{
            isShowSidebar : false
        }
    },
    methods:{
        show(){
            this.isShowSidebar = true;
        },
        hide(){
            this.isShowSidebar = false;
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 600px;
}
::v-deep .b-sidebar > .b-sidebar-header{
    font-size: 16px;
    display: block;
    padding: 0 1rem;
}
.bg-color{
    background-color:var(--primary-color);
}
::v-deep .btn-secondary{
    background-color: #fff;
    border-color: #fff;
}
::v-deep .rounded-pill{
    border-radius: 0px!important;
}
::v-deep .btn-main, .btn-mains button{
    width: 100%!important;
    background-color: var(--primary-color)!important;
    border: 1px solid var(--primary-color);
}
</style>
