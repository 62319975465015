<template>
    <div>
        <b-sidebar
            id="sidebar-sms"
            ref="sidebarSMS"
            v-model="isShowSidebar"
            aria-labelledby="sidebar-sms-header"
            right
            backdrop
            shadow
            no-header
        >
        <div class="px-3">
          <b-row class="header-filter">
            <b-col class="text-filter">Message SMS</b-col>
              <b-col class="text-right">
                <button class="btn-clear" @click.prevent="hide">
                  x
                </button>
              </b-col>
            </b-row>
              <div class="mt-3">
                  <b-row>
                      <b-col md="2" class="title-msg">Variable</b-col>
                      <b-col md="10" v-if="typeSMS === 'segment' && formMain.segmentationType === 2">
                          <b-button
                              v-for="(variable,i) in variableItemSegment"
                              :key="i"
                              class="btn-color mr-2"
                              variant="outline"
                              @click.prevent="AddVariable(variable)"
                          >{{variable.name}}</b-button>
                      </b-col>
                      <b-col md="10" v-else>
                          <b-button
                              v-for="(variable,i) in variableItem"
                              :key="i"
                              class="btn-color mr-2"
                              variant="outline"
                              @click.prevent="AddVariable(variable)"
                          >{{variable.name}}</b-button>
                      </b-col>
                  </b-row>
                  <b-row class="mt-3">
                      <b-col>
                          <label class="mb-0">Message <span class="text-error">*</span></label>
                      </b-col>
                      <b-col class="text-right">
                          <label class="mb-0">({{smsValue.length}} Character) <span class="text-error">*</span></label>
                      </b-col>
                  </b-row>
                  <div class="mt-2">
                      <b-form-textarea
                          ref="textarea"
                          v-model="smsValue"
                          placeholder="Enter something..."
                          rows="10"
                          :class="validateSMS ? 'border-error' : ''"
                      ></b-form-textarea>
                      <div v-if="validateSMS" class="mt-1 text-error"> Please input. </div>
                  </div>
                  <div class="mt-3">
                      <label>Note.</label>
                      <ul>
                          <li>1 Thai character credit consists of 67 characters</li>
                          <li>1 English character credit consists of 153 characters.</li>
                          <li>1 message can use up to 10 credits.</li>
                          <li>If use both Thai and English, credit will be counted using Thai character</li>
                      </ul>
                  </div>
              </div>
            </div>
            <template #footer>
                <div class="d-flex justify-content-between shadow align-items-center">
                    <div class="w-100">
                        <b-button
                            block
                            variant="dark"
                            class="text-light rounded-pill"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                    <div class="w-100">
                        <b-button
                            block
                            variant="light"
                            class="text-body rounded-pill btn-main"
                            @click.prevent="submitSMS"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
export default {
    props:{
        form:{
            required: true,
            type:Array
        },
        typeSMS:{
            required:true,
            type:String
        },
        formMain:{
            required: true,
            type:Object
        },
    },
    data(){
        return{
            isShowSidebar : false,
            variableItem:[
                { id: 1 , name : "firstname"},
                { id : 2 , name : "lastname" },
                { id : 3 , name : "point" } ,
            ],
            variableItemSegment:[
                { id: 1 , name : "firstname"},
                { id : 2 , name : "lastname" },
                { id : 3 , name : "point" } ,
                { id : 4 , name : "url_web"},
                { id : 5 , name : "url_line"}
            ],
            validateSMS : false,
            smsValue : ""
        }
    },
    watch:{
        smsValue(val){
            if(val){
                this.validateSMS = false;
            }else{
                this.validateSMS = true;
            }
        }
    },
    methods:{
        show() {
            this.isShowSidebar = true;
            for(const item of this.form){
                if(item.communication_type_id === 2){
                    this.smsValue = item.detail_list[0].communication_value[0].value;
                }
            }
        },
        hide() {
            this.isShowSidebar = false;
        },
        AddVariable(variable){
            const insertText = `{{${variable.name}}}`
            if (!insertText.length) return
            const textarea = this.$refs.textarea
            this.$refs.textarea.focus()
            const sentence = textarea.value
            const len = sentence.length
            let pos = textarea.selectionStart;
            if (pos === undefined) {
                pos = 0
            }
            const before = sentence.substr(0, pos);
            const after = sentence.substr(pos, len);
            this.smsValue = before + `${insertText}` + after;
            this.$nextTick().then(() => {
                textarea.selectionStart = pos + insertText.length
            })
        },
        submitSMS(){
            if(!this.smsValue){
                this.validateSMS = true;
            }else{
                for(const item of this.form){
                    if(item.communication_type_id === 2){
                        item.detail_list[0].communication_value[0].value = this.smsValue;
                    }
                }
                this.validateSMS = false;
                this.$emit('settingSMS',2);
                this.hide();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-header{
    font-size: 16px;
    display: block;
    padding: 0 1rem;
}
.bg-color{
    background-color:var(--primary-color);
}
.title-msg{
    color: #000;
    font-size: 17px;
}
.btn-outline{
    background-color: var(--secondary-color);
    border-radius: 50px;
    color: var(--primary-color);
}
.text-error{
    color: red;
}
.border-error{
    border-color: red;
}
::v-deep .btn-secondary{
    background-color: #fff;
    border-color: #fff;
}
::v-deep .rounded-pill{
    border-radius: 0px!important;
}
::v-deep .btn-main, .btn-mains button{
    width: 100%!important;
    background-color: var(--primary-color)!important;
    border: 1px solid var(--primary-color);
}
</style>
