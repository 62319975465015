<template>
  <div class="p-3 table-radius">
    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab :title="`All (${userData.result_count})`" active>
        <b-row class="mt-3">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.result"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div class="d-flex justify-content-center">
                  {{
                    $moment(data.item.sender_time).format(
                      "DD/MM/YYYY HH:mm:ss A"
                    )
                  }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center">
                  <span v-if="data.item.message_receiver === 1"
                    ><font-awesome-icon icon="spinner" class="icon-pending"
                  /></span>
                  <span v-if="data.item.message_receiver === 2"
                    ><font-awesome-icon icon="check" class="icon-check"
                  /></span>
                  <span v-if="data.item.message_receiver === 3"
                    ><font-awesome-icon icon="times" class="icon-unsuccess"
                  /></span>
                  <span v-if="data.item.message_receiver === 4">-</span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing 1 to {{ showingTo }} of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-2">
              <b-form-select
                v-model="filter.take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Email (${userData.email_count})`">
        <b-row class="mt-3">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.email"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div class="d-flex justify-content-center">
                  {{
                    $moment(data.item.sender_time).format(
                      "DD/MM/YYYY HH:mm:ss A"
                    )
                  }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center">
                  <span v-if="data.item.message_receiver === 1"
                    ><font-awesome-icon icon="spinner" class="icon-pending"
                  /></span>
                  <span v-if="data.item.message_receiver === 2"
                    ><font-awesome-icon icon="check" class="icon-check"
                  /></span>
                  <span v-if="data.item.message_receiver === 3"
                    ><font-awesome-icon icon="times" class="icon-unsuccess"
                  /></span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing 1 to {{ showingTo }} of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-2">
              <b-form-select
                v-model="filter.take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`SMS (${userData.sms_count})`">
        <b-row class="mt-3">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.sms"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div class="d-flex justify-content-center">
                  {{
                    $moment(data.item.sender_time).format(
                      "DD/MM/YYYY HH:mm:ss A"
                    )
                  }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center">
                  <span v-if="data.item.message_receiver === 1"
                    ><font-awesome-icon icon="spinner" class="icon-pending"
                  /></span>
                  <span v-if="data.item.message_receiver === 2"
                    ><font-awesome-icon icon="check" class="icon-check"
                  /></span>
                  <span v-if="data.item.message_receiver === 3"
                    ><font-awesome-icon icon="times" class="icon-unsuccess"
                  /></span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing 1 to {{ showingTo }} of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-2">
              <b-form-select
                v-model="filter.take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Line (${userData.line_count})`">
        <b-row class="mt-3">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.line"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div class="d-flex justify-content-center">
                  {{
                    $moment(data.item.sender_time).format(
                      "DD/MM/YYYY HH:mm:ss A"
                    )
                  }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center">
                  <span v-if="data.item.message_receiver === 1"
                    ><font-awesome-icon icon="spinner" class="icon-pending"
                  /></span>
                  <span v-if="data.item.message_receiver === 2"
                    ><font-awesome-icon icon="check" class="icon-check"
                  /></span>
                  <span v-if="data.item.message_receiver === 3"
                    ><font-awesome-icon icon="times" class="icon-unsuccess"
                  /></span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing 1 to {{ showingTo }} of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-2">
              <b-form-select
                v-model="filter.take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Failed to send (${userData.not_succes_count})`">
        <b-row class="mt-3">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.not_succes"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div class="d-flex justify-content-center">
                  {{
                    $moment(data.item.sender_time).format(
                      "DD/MM/YYYY HH:mm:ss A"
                    )
                  }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center">
                  <span v-if="data.item.message_receiver === 1"
                    ><font-awesome-icon icon="spinner" class="icon-pending"
                  /></span>
                  <span v-if="data.item.message_receiver === 2"
                    ><font-awesome-icon icon="check" class="icon-check"
                  /></span>
                  <span v-if="data.item.message_receiver === 3"
                    ><font-awesome-icon icon="times" class="icon-unsuccess"
                  /></span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing 1 to {{ toShow }} of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-2">
              <b-form-select
                v-model="filter.take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: {
    userData: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    rows: {
      required: false,
      type: Number,
    },
  },
  watch: {
    tabIndex(val) {
      this.$emit("chkTabIndex", val);
    },
  },
  data() {
    return {
      tabIndex: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      toShow: this.showingTo,
    };
  },
  methods: {
    hanndleChangePerpageAll() {
      this.$emit("changePage");
    },
    hanndleChangePerpageEmail() {},
    hanndleChangePerpageSms() {},
    hanndleChangePerpageLine() {},
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("getDataInPagination", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav.nav-tabs {
  background-color: white;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color);
}
::v-deep .nav-tabs {
  border: none;
}
::v-deep .nav-tabs .nav-link {
  border-color: #ededed !important;
  color: #717171 !important;
  border-right: 1px solid #ededed !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.bd-right-tablist {
  border-right: 1px solid #dfdfdf !important;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.icon-check {
  color: green;
}
.icon-pending {
  color: #000000;
}
.icon-unsuccess {
  color: red;
}
</style>
