<template>
    <div>
        <b-sidebar
            id="sidebar-line"
            ref="sidebarLine"
            v-model="isShowSidebar"
            aria-labelledby="sidebar-line-header"
            right
            backdrop
            shadow
            no-header
        >
        <div class="px-3">
          <b-row class="header-filter">
            <b-col class="text-filter">Line</b-col>
            <b-col class="text-right">
              <button class="btn-clear" @click.prevent="hide">
                x
              </button>
            </b-col>
          </b-row>
            <LineMessageTypeComponent
                :messageType="messageType"
                :list="form"
                :v="v"
                :dataImage="dataImage"
                :id="id"
                :variableItem="typeLine === 'segment' && formMain.segmentationType === 2 ? variableItemSegment : variableItem"
            />
            </div>
            <template #footer>
                <div class="d-flex justify-content-between shadow align-items-center">
                    <div class="w-100">
                        <b-button
                            block
                            variant="dark"
                            class="text-light rounded-pill"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                    <div class="w-100">
                        <b-button
                            block
                            variant="light"
                            class="text-body rounded-pill btn-main"
                            @click.prevent="submitLine"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import LineMessageTypeComponent from "@/components/automation/detail/LINE/LineMessageTypeComponent";
export default {
    components:{
        LineMessageTypeComponent
    },
    props:{
        form:{
            required:true,
            type:Array
        },
        v:{
            required:true,
            type:Object
        },
        id:{
            required:false,
            type:String
        },
        dataImage:{
            required:false,
            type:Array
        },
        messageType:{
            required:true,
            type:Array
        },
        typeLine:{
            required:true,
            type:String
        },
        formMain:{
            required:true,
            type:Object
        }
    },
    data(){
        return{
            isShowSidebar : false,
            variableItem:[
                { id: 1 , name : "firstname"},
                { id : 2 , name : "lastname" },
                { id : 3 , name : "point" }
            ],
            variableItemSegment:[
                { id: 1 , name : "firstname"},
                { id : 2 , name : "lastname" },
                { id : 3 , name : "point" } ,
                { id : 4 , name : "url_web"},
                { id : 5 , name : "url_line"}
            ],
        }
    },
    methods:{
        show() {
            this.isShowSidebar = true;
        },
        hide() {
            this.isShowSidebar = false;
        },
        submitLine(){
            this.v.$touch();
            if (this.v.$error) {
                return;
            }
            this.$emit('settingLine',3);
            this.hide();
        }
    }
}
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-header{
    font-size: 16px;
    display: block;
    padding: 0 1rem;
}
.bg-color{
    background-color:var(--primary-color);
}
.title-msg{
    color: #000;
    font-size: 17px;
}
.btn-outline{
    background-color: var(--secondary-color);
    border-radius: 50px;
    color: var(--primary-color);
}
::v-deep .btn-secondary{
    background-color: #fff;
    border-color: #fff;
}
::v-deep .rounded-pill{
    border-radius: 0px!important;
}
::v-deep .btn-main, .btn-mains button{
    width: 100%!important;
    background-color: var(--primary-color)!important;
    border: 1px solid var(--primary-color);
}
</style>
