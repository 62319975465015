<template>
  <div :class="['select-custom', { error: isValidate }]">
    <label v-if="title" class="">
      {{ title }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <br v-if="title" />

    <b-form-select
      v-bind:options="options"
      :name="name"
      :size="size"
      v-bind:value="value"
      :required="required"
      :class="['btn-select']"
      @change="onDataChange"
      :valueField="valueField"
      :textField="textField"
      :disabled="disabled"
    >
      <b-form-select-option v-if="noPleaseSelect" :value="null" disabled
        >Please select</b-form-select-option
      ></b-form-select
    >
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">Please select.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    value: {
      required: false,
      type: Number | String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    valueField: {
      required: false,
      type: String,
    },
    textField: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    noPleaseSelect: {
      required: false,
      type: Boolean,
    },
    indexCondition: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    onDataChange(event) {

      let value = this.options.find((option) => option.id === event);
      if (value) {
        this.$emit("onDataChange", event, this.indexCondition, value.name);
      } else {
        this.$emit("onDataChange", event, this.indexCondition, "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-custom.error > .btn-select {
  border-color: red !important;
}
</style>
