<template>
  <div class="title-panel">
    <b-row>
      <b-col>
        <div class="header-tablepage">Send All</div>
        <div class="count-style">
            {{all}}
        </div>
      </b-col>
      <b-col class="border-left">
        <div class="header-tablepage">Sent Successfully</div>
        <div class="count-style">
            {{success}}
        </div>
      </b-col>
      <b-col class="border-left">
        <div class="header-tablepage">Failed to Send</div>
        <div class="count-style">
            {{fail}}
        </div>
      </b-col>
      <b-col class="border-left">
        <div class="header-tablepage">% Access/Use</div>
        <div class="count-style">
            {{percen}}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
    props:{
        success:{
            required:false,
            type:Number
        },
        fail:{
            required:false,
            type:Number
        },
        percen:{
            required:false,
            type:String
        },
        all:{
            required:false,
            type:Number
        }
    }
}
</script>

<style lang="scss" scoped>
.count-style{
    font-size: 25px;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 15px;
}
::v-deep .border-left{
    border-left: 1px solid #c5c5c5 !important;
}
</style>
