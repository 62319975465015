<template>
  <div class="position-relative mb-4">
    <div :class="['text-editor-custom', { error: isValidate }]">
      <label>
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <editor
        ref="editor"
        id="custom-toolbar-button"
        :value="value"
        api-key="no-api-key"
        :init="init"
      />
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">Please Fill Out This Information</span>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "text-editor",
  components: { editor: Editor },
  props: {
    value: {
      required: false,
      type: String,
    },
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      getValue: "",
      config: {
        key: "UBB7jD6G4H5I3B2C10aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6A5C2C4D3H3G3H3==",
        events: {
          "image.inserted": async ($img) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = async () => {
              var recoveredBlob = xhr.response;
              var reader = new FileReader();
              reader.onload = async () => {
                var blobAsDataUrl = reader.result;
                var form = {
                  Base64String: blobAsDataUrl,
                };
                await this.$store.dispatch("ActionUploadImage", form);
                var data = await this.$store.state.moduleConnectAPI
                  .stateUploadImage;
                $img[0].src = data.detail + "#!";
              };
              var image = await reader.readAsDataURL(recoveredBlob);
            };
            xhr.open("GET", $img[0].src);
            xhr.send();
            //  xhr.abort();
          },
        },
        charCounterCount: true,
        toolbarInline: true,
        showOnMobile: true,
        heightMin: 400,
        fileUpload: false,
        imageUpload: true,
        imageDefaultAlign: null,
        imageDefaultDisplay: null,
        useClasses: false,
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote",
            ],
          },
          moreRich: {
            buttons: [
              "insertLink",
              "insertImage",
              "insertVideo",
              "insertTable",
              "emoticons",
              "fontAwesome",
              "specialCharacters",
              "embedly",
              // "insertFile",
              "insertHR",
            ],
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              // "print",
              // "getPDF",
              // "spellChecker",
              "selectAll",
              "html",
              "help",
            ],
            align: "right",
            buttonsVisible: 2,
          },
        },
        fontFamily: {
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
          "DB-HeaventRounded-Regular": "DB-HeaventRounded-Regular",
          "DB-HeaventRounded-Bold": "DB-HeaventRounded-Bold",
          "DB-HeaventRounded-Medium": "DB-HeaventRounded-Medium",
          "DB-HeaventRounded-Blk": "DB-HeaventRounded-Blk",
          "DB-HeaventRounded-LightItalic": "DB-HeaventRounded-LightItalic",
        },
        videoUpload: false,
      },
      init: {
        height: 500,
        init_instance_callback: (editor) => {
          editor.on("change", (e) => {
            this.$emit("onDataChange", editor.getContent());
            this.getValue = editor.getContent();
          });
        },
        selector: "editor#custom-toolbar-button",
        setup: function (editor) {
          editor.ui.registry.addButton("customInsertFirstname", {
            text: "Firstname",
            onAction: function (_) {
              editor.insertContent("{{firstname}}");
            },
          });
          editor.ui.registry.addButton("customInsertLastname", {
            text: "Lastname",
            onAction: function (_) {
              editor.insertContent("{{lastname}}");
            },
          });
          editor.ui.registry.addButton("customInsertPoint", {
            text: "Point",
            onAction: function (_) {
              editor.insertContent("{{point}}");
            },
          });
          editor.ui.registry.addButton("customInsertUrlWeb", {
            text: "Url Web",
            onAction: function (_) {
              editor.insertContent("{{url_web}}");
            },
          });
          editor.ui.registry.addButton("customInsertUrlLine", {
            text: "Url Line",
            onAction: function (_) {
              editor.insertContent("{{url_line}}");
            },
          });
        },

        plugins: [
          "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
        ],
        menubar: "file edit view insert format tools table help",
        toolbar:
          "customInsertFirstname | customInsertLastname | customInsertPoint | customInsertUrlWeb | customInsertUrlLine |bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        toolbar_mode: "sliding",
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        importcss_append: true,
        image_caption: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        images_upload_handler: async (blobInfo, success, failure, progress) => {
          if (blobInfo.blob().size / 1024 / 1024 > 5) {
            failure("Maximun image size 5 MB");
            return;
          }
          var body = {
            base64: blobInfo.base64(),
          };
          var config = {
            onUploadProgress: (progressEvent) => {
              progress((progressEvent.loaded / progressEvent.total) * 100);
            },
          };
          var form = {
            Base64String: "data:image/png;base64," + blobInfo.base64(),
          };
          await this.$store.dispatch("ActionUploadImage", form);
          var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
          if (data.detail != "") {
            success(data.detail);
          } else {
            failure("Upload fail");
          }
          // this.axios
          // .post(`${this.$baseUrl}/api/image/WYSIWYG/save`, body, config)
          // .then(data => {
          //     if (data.url != "") {
          //         success(data.url);
          //     } else {
          //         failure("Upload fail");
          //     }
          // })
          // .catch(e => {
          //     failure(e);
          // });
        },
      },
    };
  },
  methods: {
    onDataChange(val) {
      this.$emit("onDataChange", val);
    },
  },
};
</script>

<style scoped>
.text-editor-custom {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.text-editor-custom.error {
  border: 2px solid red !important;
}
.text-error {
  color: red;
}
::v-deep .tox-notifications-container {
  display: none !important;
}
.btn-outline {
  background-color: #dbdbdb;
  border-radius: 20px;
  color: #000;
}
.title-msg {
  color: #000;
  font-size: 17px;
}
</style>
