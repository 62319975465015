<template>
  <div>
    <b-row>
      <b-col md="12" class="hide-label">
        <InputTextAreaVariable
          :reference="'textarea'"
          class="mb-4"
          type="text"
          textFloat="Text"
          placeholder=""
          name="text"
          rows="5"
          :isValidate="v.value.$error"
          :v="v.value"
          v-model="text.value"
          :response="text"
          :variableItem="variableItem"
        />
      </b-col>
      <b-col md="12">
        <span class="text-main-color f-size-16 font-weight-bold">Image</span>
      </b-col>
      <b-col md="12">
        <ImageUpload
          class="mt-1"
          :v="v.image_url"
          v-bind:dataFile="text.image_url"
          @handleChangeImage="handleChangeImage"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="">
        <div class="d-flex">
          <div>
            <span class="text-main-color f-size-16">Options</span>
          </div>
          <div class="ml-auto btn-add" @click.prevent="addButtonResponseOptions">
            <u class="pointer text-blue">Add</u>
          </div>
        </div>
        <div class="mt-2">
          <div
            v-for="(button, bindex) in respLine.format_list"
            :key="bindex"
            class="p-3 button-box mb-2"
          >
            <div class="d-flex">
              <div class="f-size-16 text-main-color">
                Option # {{ bindex + 1 }}
              </div>
              <div class="ml-auto" v-if="respLine.format_list.length > 1">
                <b-button clas="bg-none" @click.prevent="onDeleteButtonOption(bindex)">
                  <font-awesome-icon
                    icon="trash-alt"
                    class="pointer"
                    color="#4f5d73"
                  />
                </b-button>
              </div>
            </div>
            <div class="mt-3">
              <ButtonResponse
                class="button-form"
                :button="button"
                :v="vFormat.$each.$iter[bindex]"
                :buttonIndex="bindex"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputTextAreaVariable from "@/components/inputs/InputTextAreaVariable";
import ImageUpload from "@/components/automation/detail/LINE/TypeMessage/ImageUpload";
import ButtonResponse from "@/components/automation/detail/LINE/TypeMessage/ButtonResponse";
export default {
  components: {
    ImageUpload,
    InputTextAreaVariable,
    ButtonResponse
  },
  props: {
    response: {
      required: true,
      type: Object
    },
    v: {
      required: false,
      type: Object
    },
    vFormat:{
      required:true,
      type:Object
    },
    text:{
      required:true,
      type:Object
    },
    variableItem:{
      required:true,
      type:Array
    }
  },
  data(){
    return{
      respLine : this.response
    }
  },
  methods: {
    handleChangeImage(image) {
      this.text.image_url = image;
    },
    onDeleteButtonOption(index) {
      this.respLine.format_list.splice(index, 1);
       this.respLine.format_list.forEach((element, bindex) => {
        element.sort_order = bindex + 1;
      });
    },
    scrollToEnd: function() {
      var container = document.getElementsByClassName("button-form");
      setTimeout(() => {
        container[container.length - 1].scrollIntoView(false);
      }, 0);
    },
    addButtonResponseOptions() {
      let body = {
        message_type_id:7,
        format_id:1,
        option_name:"",
        action_name:"",
        sort_order:this.respLine.format_list.length + 1
      };
      this.respLine.format_list.push(body);
      // this.scrollToEnd();
    }
  }
};
</script>

<style lang="scss" scoped>
.button-box {
  background-color: #cdcae2;
  border-radius: 6px;
}
.btn-add{
  background-color: transparent;
  border: none;
  text-decoration: underline;
}
</style>
